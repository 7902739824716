import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DetailBlock } from '../../Components/DetailBlock/DetailBlock';

interface IBidAnalysisProps {

}

export const BidAnalysis: StatelessComponent<IBidAnalysisProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered header'>
                            <h2>Bid Analysis</h2>
                        </Col>
                    </Row>
                    <DetailBlock
                        detailImage="/images/screenshots/Bid_NLP.png"
                        detailImageAlt="Natural Language Processing"
                        detailTitle="Natural Language Processing"
                        detailText="Adaptive Catalog uses an advanced natural language engine to find keywords like “Ultrabook” or “Workstations” in your RFPs to help identity requested products. The bid analysis engine also compares the RFP to millions of products and identifies specifically mentioned products."
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/Bid_Attribute_Matching.png"
                        detailImageAlt="Product Attribute Matching"
                        detailTitle="Product Attribute Matching"
                        detailText="Adaptive Catalog analyzes your RFPs to find specifically mentioned features and specifications for your product data. It can also find features that have been marked as exclusions, such as mobile broadband or wireless cards that may not be allowed on a bid response."
                        imageRight
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/Bid_Certification.png"
                        detailImageAlt="TAA Warning"
                        detailTitle="Certification Reporting"
                        detailText="Analyze your response to RFPs for TAA, Energy Star, and Country of Origin compliance. Get notified when you add a product that may not meet the requirements of the RFP to your workspace."
                    />
                    <Row className="centered">
                        <Col lg={12} md={12} sm={12} className='centered footer'>
                            <a href="/" className="btn btn-light">Home</a>&nbsp;&nbsp;
                            <a href="/Features/CRM-ERPIntegrations" className="btn btn-light">Continue to CRM/ERP Integrations</a>
                        </Col>   
                    </Row>

                </Container>
            </Container>
        </>
    );
}