import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DetailBlock } from '../../Components/DetailBlock/DetailBlock';

interface ICatalogsProps {

}

export const Catalogs: StatelessComponent<ICatalogsProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered header'>
                            <h2>Catalogs</h2>
                        </Col>
                    </Row>
                    <DetailBlock
                        detailImage="/images/screenshots/Catalog_Rich_Product_Data.png"
                        detailImageAlt="Rich Product Data"
                        detailTitle="Rich Product Data"
                        detailText="Adaptive Catalog provides rich content for millions of technology products, including marketing information, pictures, sales brochures, and accurate technical specifications."
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/Catalog_Sales_Recommendations.png"
                        detailImageAlt="Sales Recommendations"
                        detailTitle="Sales Recommendations"
                        detailText="Find compatible accessories, replacement products, services, and warranties. View manufacturer, distributor, and industry professional recommendations to cross sell and up sell on every product."
                        imageRight
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/Catalog_Customizability.png"
                        detailImageAlt="Customizability"
                        detailTitle="Customizability"
                        detailText="Easily update product descriptions and images to match your company’s marketing standards and highlight the features that your customers care about.  Add your own configurations for products and services to personalize your catalog."
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/Catalog_Favorites_Notes.png"
                        detailImageAlt="Favorites &amp; Notes"
                        detailTitle="Favorites &amp; Notes"
                        detailText="Keep up to date on your company’s best sellers and products that you are promoting by setting them as individual or company favorites. Add internal notes to track important information and transfer knowledge to new sales reps."
                        imageRight
                    />
                    <Row className="centered">
                        <Col lg={12} md={12} sm={12} className='centered footer'>
                            <a href="/" className="btn btn-light">Home</a>&nbsp;&nbsp;
                            <a href="/Features/SearchCustomization" className="btn btn-light">Continue to Search Customization</a>
                        </Col>   
                    </Row>

                </Container>
            </Container>
        </>
    );
}