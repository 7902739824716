import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ContactForm } from '../Components/ContactForm/ContactForm';
import { WalkThrough } from '../Components/WalkThrough/WalkThrough';
import { Banner } from '../Components/Banner/Banner';
import { Role } from '../Components/Role/Role';

interface IHomePageProps {

}

export const HomePage: StatelessComponent<IHomePageProps> = (props) => {
    return (
        <>
            <Container className='body-content'>
                {/* <AdaptiveCatalogCarousel
                    items={carouselItems}
                    slideTime={6000}
                /> */}
                <Banner
                    headline="SEARCH. CURATE. CONNECT."
                    detailText="Adaptive Catalog provides you with a personalized catalog, where you can search across all your distributors, internal products, and service providers on one easy to use screen. Valuable product information is right at your fingertips, including part numbers, pictures, videos, and community recommendations. Your sales people will be able to create configurations and recommendations so that they can spend less time sourcing and more time selling!"
                    graphic="/images/laptopWithScreenshotTransparent.png"
                    graphicAlt="Adaptive Catalog on laptop"
                />
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/Catalogs">
                            <WalkThrough icon="/images/catalogsSketch.png" iconAlt="catalog icon" title="CATALOGS" description="Create personalized catalogs to keep track of all of your products in one place." />
                        </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/SearchCustomization">
                            <WalkThrough icon="/images/searchCustomizationSketch.png" iconAlt="search customization icon" title="SEARCH CUSTOMIZATION" description="Find products that match all of your criteria with custom search features." />
                        </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/RealTimeAvailability">
                            <WalkThrough icon="/images/realTimeSketch.png" iconAlt="real time availability icon" title="REAL TIME AVAILABILITY" description="See pricing and availability in real time from all of your vendors." />
                        </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/ECommerce">
                            <WalkThrough icon="/images/eCommerceSketch.png" iconAlt="eCommerce icon" title="E-COMMERCE" description="Manage your e-commerce website with ease from a central location." />
                        </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/BidAnalysis">
                            <WalkThrough icon="/images/bidAnalysisSketch.png" iconAlt="bid analysis icon" title="BID ANALYSIS" description="Analyze complex RFPs to ensure your bids are a perfect fit." />
                        </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/CRM-ERPIntegrations">
                            <WalkThrough icon="/images/CRM-ERPSketch.png" iconAlt="CRM-ERP icon" title="CRM/ERP INTEGRATIONS" description="Synchronize all of your product data across multiple platforms." />
                        </a>
                    </Col>
                </Row>
                <Row className="divider">
                </Row> 
                <Row>
                    <Col lg={4} md={4} sm={12}>
                        <Role icon="/images/salesPeopleIcon.png" iconAlt="salespeople icon" title="Salespeople" description="Industry leading search features help salespeople find products faster and instantly see current availability." />
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <Role icon="/images/procurementIcon.png" iconAlt="procurememt icon" title="Procurement" description="Delight your procurement team with consolidated product sources in one seamless interface." />
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <Role icon="/images/salesEngineersIcon.png" iconAlt="sales engineer icon" title="Sales Engineers" description="Sales engineers will appreciate viewing product specifications for all products in one easy place and filtering to search for specific technical requirements." />
                    </Col>
                </Row>
                
                <Row>
                    <Col  lg={12} md={12} sm={12} id="demoVideo" className="centered">
                    <div className="embed-container"> 
                        <iframe  title="Demo Video" src="https://www.youtube.com/embed/ip7R6p5dtpY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    </Col>
                </Row>
                
                <Container>
                    <Row>
                        {/* <Col lg={6} md={12} sm={12} className='centered'>
                            <h3>What we do</h3>
                            <Jumbotron className='left'>
                                Adaptive Catalog provides you with a personalized catalog, where you can search across all your distributors, internal products, and service providers on one easy to use screen. Valuable product information is right at your fingertips, including part numbers, pictures, videos, and community recommendations. Your sales people will be able to create configurations and recommendations so that they can spend less time sourcing and more time selling!
                        </Jumbotron>
                        </Col> */}
                        <Col id="contactForm" lg={12} md={12} sm={12}>
                            <h3 className='centered'>Schedule a Demo</h3>
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}