import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DetailBlock } from '../../Components/DetailBlock/DetailBlock';

interface ICRMERPIntegrationsProps {

}

export const CRMERPIntegrations: StatelessComponent<ICRMERPIntegrationsProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered header'>
                            <h2>CRM/ERP Integrations</h2>
                        </Col>
                    </Row>
                    <DetailBlock
                        detailImage="/images/screenshots/CRM_Salesforce.png"
                        detailImageAlt="Salesforce Certified"
                        detailTitle="Salesforce Certified"
                        detailText="Keeping the Salesforce Pricebooks updated with Manufacturer data is seamless with Adaptive Catalog.  The two-way integration allows you to import your existing products and services into Adaptive Catalog and automatically synchronizes your search results to Salesforce."
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/CRM_Salesforce_Export.png"
                        detailImageAlt="Salesforce Export"
                        detailTitle="Salesforce Export"
                        detailText="From your Catalog Workspace, you can also directly export your products to a Salesforce opportunity or quote."
                        imageRight
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/CRM_Netsuite.png"
                        detailImageAlt="NetSuite Enabled"
                        detailTitle="NetSuite Enabled"
                        detailText="Product categorization is an important aspect of good accounting practices in NetSuite.  The Adaptive Catalog integration ensures that any new products you sell are properly configured inside of NetSuite and tracked to the right accounts."
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/CRM_Netsuite_Inventory.png"
                        detailImageAlt="Inventory Visibility"
                        detailTitle="Inventory Visibility"
                        detailText="The two-way integration also checks for product price and availability from distributors and blends it with the inventory information in NetSuite so you have the full picture for procurement and order processing."
                        imageRight
                    />
                    <Row className="centered">
                        <Col lg={12} md={12} sm={12} className='centered footer'>
                            <a href="/" className="btn btn-light">Home</a>
                        </Col>   
                    </Row>

                </Container>
            </Container>
        </>
    );
}