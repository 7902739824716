import React, { Component } from 'react';
import createHistory from 'history/createBrowserHistory'
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import { HomePage } from './Pages/HomePage';
import { ChannelPartnersExpo2020 } from './Pages/ChannelPartnersExpo2020';
import { ExecutiveTeam } from './Pages/ExecutiveTeam';
import { Pricing } from './Pages/Pricing';
import { Catalogs } from './Pages/WalkThroughDetails/Catalogs';
import { SearchCustomization } from './Pages/WalkThroughDetails/SearchCustomization';
import { RealTimeAvailability } from './Pages/WalkThroughDetails/RealTimeAvailability';
import { ECommerce } from './Pages/WalkThroughDetails/ECommerce';
import { BidAnalysis } from './Pages/WalkThroughDetails/BidAnalysis';
import { CRMERPIntegrations } from './Pages/WalkThroughDetails/CRMERPIntegrations';
import { Videos } from './Pages/Videos';

const history = createHistory();
history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
});

export default class ACRouter extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={HomePage} />
                    <Route exact path='/conferences/channelPartnersExpo2020' component={ChannelPartnersExpo2020} />
                    <Route exact path='/ExecutiveTeam' component={ExecutiveTeam} />
                    <Route exact path='/Pricing' component={Pricing} />
                    <Route exact path='/Videos' component={Videos} />
                    <Route exact path='/Features/Catalogs' component={Catalogs} />
                    <Route exact path='/Features/SearchCustomization' component={SearchCustomization} />
                    <Route exact path='/Features/RealTimeAvailability' component={RealTimeAvailability} />
                    <Route exact path='/Features/ECommerce' component={ECommerce} />
                    <Route exact path='/Features/BidAnalysis' component={BidAnalysis} />
                    <Route exact path='/Features/CRM-ERPIntegrations' component={CRMERPIntegrations} />
                </Switch>
            </BrowserRouter>
        )
    }
}