import React, { StatelessComponent } from 'react';
import {  Row, Col } from 'reactstrap';

interface IBannerProps {
    headline: string;
    detailText: string;
    graphic: string;
    graphicAlt: string;
}

export const Banner: StatelessComponent<IBannerProps> = (props) => {
    return (
        <Row className='banner'>
            <Col className="bannerContent" lg={8} md={12} sm={12}>
                <h1>{props.headline}</h1>
                <p>{props.detailText}</p>
                <a href="/#demoVideo" className="btn btn-light">VIEW DEMO</a>
            </Col>
            <Col className="bannerImg" lg={4} md={12} sm={12}>
                <img src={props.graphic} alt={props.graphicAlt}/>
            </Col>
        </Row>
    )
}