import React, { StatelessComponent } from 'react';
import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';

interface IRoleProps {
    icon: string;
    iconAlt?: string;
    title: string;
    description: string;
}

export const Role: StatelessComponent<IRoleProps> = (props) => {
    return (
        <Card className="role">
            <CardImg top width="100%" src={props.icon} alt={props.iconAlt || props.title} />
            <CardBody>
                <CardTitle>{props.title}</CardTitle>
                <CardText>{props.description}</CardText>
            </CardBody>
        </Card>
    )
}