import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { PriceDisplay } from '../Components/PriceDisplay/PriceDisplay';


interface IPricingProps {

}

export const Pricing: StatelessComponent<IPricingProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered'>
                            <h2>Pricing</h2>
                        </Col>
                    </Row>
                    <PriceDisplay
                        header="BASE PRICE"
                        price="$499"
                        priceMod=" /month"
                    >
                    <p>Includes 5 catalogs, basic API access, and extension authoring support.</p>  
                    </PriceDisplay>
                    <PriceDisplay
                        header="ADDITIONAL NAMED USER"
                        price="$10"
                        priceMod=" /month"
                    >
                    <p>Includes unique dashboards, favorites, and workspaces.</p>

                    </PriceDisplay>
                    <PriceDisplay
                        header="ADDITIONAL CATALOG"
                        price="$50"
                        priceMod=" /month"
                    >
                        
                    </PriceDisplay>
                    <PriceDisplay
                        header="ADAPTIVE CATALOG RICH CONTENT"
                        price="$499"
                        priceMod=" /month"
                    >
                        <p>Rich metadata on millions of technology products including images, specifications, marketing information, and compatibility details.</p>
                    </PriceDisplay>
                    <PriceDisplay
                        header="ENTERPRISE API ACCESS"
                        price="Contact Us"
                        priceMod=" "
                    >
                        <p>For direct API access to your product catalogs, allowing you to write any custom intergrations or create custom reports. Includes API technical support.</p>
                    </PriceDisplay>


                </Container>
            </Container>
        </>
    );
}