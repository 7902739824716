import React, { StatelessComponent } from 'react';
import { Row, Col } from 'reactstrap';

interface IPriceDisplayProps {
    header: string;
    price: string;
    priceMod: string;
}

export const PriceDisplay: StatelessComponent<IPriceDisplayProps> = (props) => {
    return (
        <Row className="priceDisplay">
            <Col lg={8} md={9} sm={12}>
                <h1>{props.header}</h1>
                {props.children}
            </Col>
            <Col className="priceStyle" lg={4} md={3} sm={12}>
                <p>{props.price}<span className='priceMod'>{props.priceMod}</span></p>
            </Col>
        </Row>
    )
}